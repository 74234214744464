"use client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { Feature, FeatureControl } from "./types";

const FeatureControlContext = createContext<FeatureControl>({});

export function FeatureControlProvider(
  props: PropsWithChildren<{ featureControl: FeatureControl }>
) {
  const { children, featureControl } = props;
  const [fc, setFc] = useState<FeatureControl>(createProxy(featureControl));

  /**
   * This logic is to inject the feature control from local storage.
   * The convention is to use `fc-` prefix for the key.
   * Then the `fc-` prefix will be removed and the rest of the key will be used as the feature key.
   * Why useEffect? Because `window` is only defined inside the browser. Will cause an error on the server side.
   */
  useEffect(() => {
    const fc: FeatureControl = {};
    Array.from(
      { length: window.localStorage.length },
      (_, index) => window.localStorage.key(index) as string
    ).forEach((key) => {
      if (key.startsWith("fc-")) {
        const enabled = window.localStorage[key] === "true";
        const fcKey = key.replace(/^fc-/, "");

        fc[fcKey] = fc[fcKey] ?? {};
        fc[fcKey].enabled = enabled;
      }
    });

    if (Object.keys(fc).length) {
      setFc((prev) => createProxy({ ...prev, ...fc }));
    }
  }, []);

  return (
    <FeatureControlContext.Provider value={fc}>
      {children}
    </FeatureControlContext.Provider>
  );
}

export function useGlobalFeatureControl() {
  const value = useContext(FeatureControlContext);

  if (value === undefined) {
    throw new Error("`FeatureControlContext` could not find any provider");
  }

  return value;
}

export function useFeatureControl<T = unknown>(featureKey: string) {
  return useGlobalFeatureControl()[featureKey] as Feature<T>;
}

// Feature Control
const defaultFc = { enabled: null, properties: {} };
function createProxy(value: FeatureControl) {
  return new Proxy(value, {
    get(obj, key: string) {
      return obj[key] ?? defaultFc;
    },
  });
}
